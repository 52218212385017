@import url('https://fonts.googleapis.com/css2?family=Chelsea+Market&family=Rubik+Wet+Paint&display=swap');
.App {
  text-align: center;
}


body {
  font-family: 'Rubik Wet Paint', cursive 'Chelsea Market', cursive;
}

code {
  font-family: 'Rubik Wet Paint', cursive 'Chelsea Market', cursive;
}
