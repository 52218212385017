.App {
  text-align: center;
  min-height: 100vh;
  background-color: rgba(0, 217, 255, 0.75);
  background-image: url(./assets/Background.webp);
  background-repeat: no-repeat;
  background-size: cover;
}

header {
  display: flex;
  min-height: 188px;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  font-family: 'Rubik Wet Paint'
}

.faqText {
  text-align: left;
  font-size: 1.25rem;
  font-weight: 500;
  font-family: 'Rubik Wet Paint';
  text-decoration: none;
}

.HeaderTitle {
  font-size: 3rem;
}

/*Minter*/
.container {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  min-height: 80vh;
  font-family: 'Chelsea Market', cursive; 

}

.mintPage {
  align-items: center;
  text-align: center;
  justify-content: center;
}

.mintImage {
  border-radius: 50%;
}

.countContainer{
  justify-content:center;
  text-align: center;
  align-items: center;
  display: block;
}

.mintAmount {
  font-size: 2em;
  padding-top: .5rem;
}


.mintTitle{
  font-size: 4em;
  font-weight: bold;
  font-family: 'Rubik Wet Paint'
}
.mintdescription {
  padding: .5rem;
  font-size: 20px;
  font-weight: bold;
}

.mintButton {
  font-size: 1.25em;
  padding: 0rem 1.25rem;
  min-width: 7rem;
  min-height: 3rem;
  border-radius: 12px;
  background-color: rgba(0, 247, 227, 0.75);
}

.mintSupply{
  padding: 5rem;
  font-size: 20px;
  font-weight: bold;
}


@media only screen and (max-width: 600px) {

  .App {
    min-height: 160vh;
  }

  header {
    justify-content: center;
  }
  .HeaderTitle {
    display: none;
  }

  .container {
    height: 160vh;
  }

  .mintSupply{
    padding: .5rem;
    font-size: 20px;
    font-weight: bold;
  }
}